.reports-ctn {
  border-radius: 4px;
  // max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  .header-ctn {
    padding-top: 20px;
    ion-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .page-title {
      color: var(--reports-title-color);
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.01em;
      float: left;
      margin-right: auto;
      margin-top: 20px;
    }

    .img-page-title-row {
      display: inline-flex;
      align-items: center;
      line-height: 42px;
      margin-right: auto;

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 4px;
        margin-top: -3px;
      }

      .title {
        margin-left: 4px;
        font-size: 36px;
        font-family: var(--headers-font-family);
        color: var(--ion-text-color);
      }
    }
  }

  .filters-row {
    ion-select,
    .date-control {
      border: 1px solid var(--filter-controls-border);
    }

    .filter-label {
      color: var(--input-text-color);
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px;
    }

    .MuiFormControl-root,
    ion-select {
      max-height: 44px;
      max-width: 220px;
    }
  }

  .content-ctn {
    padding: 0px 20px 20px 0px;
    background-color: var(--ion-card-background);
    border-radius: 6px;

    &.light-bg {
      background-color: var(--reports-bg-color);
    }

    .tbl-ctn {
      border-radius: 6px;
      .MuiTableContainer-root {
        .MuiTable-root {
          background: var(--webkit-gradient-background-2);
          background: var(--linear-gradient-background-2);
          .MuiTableHead-root {
            .MuiTableCell-head {
              color: #fff !important;
            }
          }
          .MuiTableBody-root {
            background-color: #fff;
            .MuiTableRow-root:nth-child(even) {
              background: #fff;
            }
            .MuiTableRow-root:nth-child(odd) {
              background: rgba(207, 207, 207, 0.3);
            }
          }
        }
      }

      // .no-data-row {
      //   background: #fff !important;
      // }
    }

    .tbl-ctn:not(.no-hov-style) {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:hover {
              background-color: rgba(255, 167, 36, 0.2) !important;
            }
          }
        }
      }
    }

    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:nth-child(even) {
              background: var(--ion-card-background-contrast);
            }
            .MuiTableRow-root:hover {
              background-color: rgba(255, 167, 36, 0.2) !important;
            }
            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
          }
        }
      }
    }
  }

  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 2px solid #636c89;
      display: inline-flex;
      height: 48px;
      border-radius: 4px;
      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;
        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }
      }
    }
  }

  .no-data-row {
    background: #fff;
    width: 100%;
    border-bottom: 0px !important;
    color: var(--ion-text-color-contrast);
    height: 40px;
    padding-top: 15px;
    padding-left: 15px;
    font-family: var(--ion-font-family);
    text-align: center;
  }

  .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
    color: var(--input-text-color);
    font-family: Inter;
    &.profit {
      color: var(--ion-color-success) !important;
    }
    &.loss {
      color: var(--ion-color-danger) !important;
    }
  }
}

@media (min-width: 721px) and (max-width: 1280px) {
  .reports-ctn .header-ctn {
    .page-title,
    .img-page-title-row {
      padding-left: 20px !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }
  .reports-ctn {
    margin: 10px 0px !important;
    .header-ctn,
    .content-ctn {
      padding: 0 8px;
      .points-type-toggle {
        border: 1px solid #636c89;
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }

      .filters,
      .filters-row {
        margin-top: 8px;
        font-size: 12px;
        ion-select,
        .MuiFormControl-marginNormal {
          height: 32px !important;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .page-title {
        font-size: 26px;
        line-height: 22px;
        padding-left: 10px !important;
      }

      .img-page-title-row {
        margin-left: 10px;
        display: flex;
        align-items: baseline;
        .title-image {
          height: 21px;
          width: 21px;
        }
        .title {
          font-size: 26px;
          line-height: 22px;
          margin-left: 6px;
        }
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }
        .summary-expand-toggle {
          text-align: center;
          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;
            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }

    .tbl-ctn .MuiTableContainer-root .MuiTable-root {
      background: var(--webkit-gradient-background-1);
      background: var(--linear-gradient-background-1);
    }
    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }
    }

    .txt-info {
      font-weight: bold;
      font-size: 13px;
    }

    .mob-fs-13 {
      font-weight: bold !important;
      font-size: 13px;
      text-transform: capitalize;
    }

    .mob-fs-14 {
      font-weight: bold !important;
      font-size: 14px;
      text-transform: capitalize;
    }

    .mob-fs-16 {
      font-weight: bold !important;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}
